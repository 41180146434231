import React, { useState, useEffect } from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql, Link } from "gatsby"
import { Container, Nav } from "react-bootstrap"
import piwik from "../config/piwik"
import { Button } from "react-bootstrap"

import Header from "./header"

import "../../node_modules/prismjs/themes/prism.css"
import "../../node_modules/boosted/dist/css/boosted.min.css"
import "../../node_modules/boosted/dist/css/orangeIcons.min.css"
import "./layout.css"

const Layout = ({ children }) => {
  const [existsCookie, setExistsCookie] = useState()

  useEffect(() => {
    if (document.cookie.indexOf("_pk") !== -1) {
      if (
        document.cookie.indexOf("mtm_consent") === -1 &&
        document.cookie.indexOf("mtm_consent_removed") === -1
      ) {
        setExistsCookie(false)
      } else {
        setExistsCookie(true)
      }
    } else {
      setExistsCookie(true)
    }
  })

  const giveConsent = function () {
    piwik.push(["rememberConsentGiven"])
    setExistsCookie(true)
  }

  const rejectConsent = function () {
    piwik.push(["forgetConsentGiven"])
    setExistsCookie(true)
  }

  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  return (
    <>
      <Header siteTitle={data.site.siteMetadata.title} />
      <main>
        <Container className="pt-3 pb-5">{children}</Container>
      </main>
      <footer className="o-footer" role="contentinfo">
        <div className="o-footer-bottom">
          <Container>
            <Nav bg="dark" variant="dark">
              <Nav.Item>
                <Nav.Link as={Link} to="/legal-matters">
                  Legal matters
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link as={Link} to="/terms-and-conditions">
                  Terms and conditions
                </Nav.Link>
              </Nav.Item>
            </Nav>
          </Container>
        </div>
      </footer>
      {existsCookie === false && (
        <div className="ask-consent">
          <div className="text-consent">
            <p style={{ fontSize: "0.83rem", marginBottom: "0" }}>
              This site uses Matomo to analyze traffic and help us to improve
              your user experience. Your IP address is anonymised <br></br>
              and cookies are stored on your browser for 13 months. This data is
              only processed by us and our web hosting platform.
            </p>
          </div>
          <div className="buttons-consent">
            <Button
              onClick={giveConsent}
              variant="secondary"
              style={{ backgroundColor: "#ff7900", fontSize: "0.84rem" }}
            >
              Accept
            </Button>
            <div className="divider" />
            <Button
              onClick={rejectConsent}
              variant="secondary"
              style={{ fontSize: "0.84rem" }}
            >
              Reject
            </Button>
          </div>
        </div>
      )}
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
