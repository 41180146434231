import { Link } from "gatsby"
import React from "react"
import { connect } from "react-redux"
import { Container, Navbar, NavDropdown, Nav } from "react-bootstrap"

import orangeLogo from "../../node_modules/boosted/dist/img/orange_logo.svg"
import { logOutUser } from "../actions"

const Header = ({
  acceptedConditions,
  admin,
  connected,
  email,
  loaded,
  logoutUser,
}) => (
  <header role="banner">
    <Navbar bg="dark" variant="dark" expand="lg">
      <Container>
        <Navbar.Brand as={Link} to="/">
          <img
            alt="Orange logo"
            src={orangeLogo}
            width="50"
            height="50"
            loading="lazy"
          />
          <span className="h1 mb-0">Khiops</span>
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="navbar-nav" />
        <Navbar.Collapse id="navbar-nav">
          <Nav className="mr-auto">
            {!acceptedConditions &&
              connected && (
                <Nav.Link as={Link} to="/onboarding" activeClassName="active">
                  Welcome
                </Nav.Link>
              )}
            {connected &&
              acceptedConditions && (
                <Nav.Link
                  as={Link}
                  to="/installation-notes-v10"
                  activeClassName="active"
                >
                  Install
                </Nav.Link>
              )}
            {connected &&
              acceptedConditions && (
                <Nav.Link as={Link} to="/my-licenses" activeClassName="active" data-testid="my-licenses">
                  Licenses
                </Nav.Link>
              )}
            {connected &&
              email.endsWith("orange.com") &&
              acceptedConditions && (
                <Nav.Link as={Link} to="/my-tokens" activeClassName="active">
                  API & Tokens
                </Nav.Link>
              )}
            {connected &&
              acceptedConditions && (
                <NavDropdown title="Tools" id="nav-dropdown">
                  <NavDropdown.Item as={Link} to="/tools/pykhiops/install">
                    pyKhiops
                  </NavDropdown.Item>
                  <NavDropdown.Item as={Link} to="/tools/interpretation">
                    Khiops Interpretation
                  </NavDropdown.Item>
                  <NavDropdown.Item as={Link} to="/tools/enneade">
                    Khiops Ennéade
                  </NavDropdown.Item>
                  <NavDropdown.Item as={Link} to="/tools/patatext">
                    Patatext
                  </NavDropdown.Item>
                  {email.endsWith("orange.com") && (
                    <NavDropdown.Item as={Link} to="/tools/docker">
                      Docker
                    </NavDropdown.Item>
                  )}
                  {email.endsWith("orange.com") && (
                    <NavDropdown.Item as={Link} to="/tools/artifactory">
                      Artifactory
                    </NavDropdown.Item>
                  )}
                  {email.endsWith("orange.com") && (
                    <NavDropdown.Item as={Link} to="/tools/api-and-tokens">
                      API and Tokens
                    </NavDropdown.Item>
                  )}
                </NavDropdown>
              )}

            <NavDropdown title="Documentation" id="nav-dropdown">
              <NavDropdown.Item as={Link} to="/pdf/KhiopsGuide.pdf">
                Khiops
              </NavDropdown.Item>
              <NavDropdown.Item as={Link} to="/pdf/KhiopsCoclusteringGuide.pdf">
                Khiops Colustering
              </NavDropdown.Item>
              <NavDropdown.Item as={Link} to="/pdf/KhiopsTutorial.pdf">
                Tutorial
              </NavDropdown.Item>
              <NavDropdown.Item as={Link} to="/html/pykhiops-doc/index.html">
                pyKhiops
              </NavDropdown.Item>
            </NavDropdown>

            <Nav.Link as={Link} to="/contact" activeClassName="active">
              Contact
            </Nav.Link>
          </Nav>
          {loaded && (
            <Nav className="ml-auto">
              {connected && acceptedConditions ? (
                <NavDropdown
                  alignRight
                  title={<span title={email} className="svg-avatar"></span>}
                  id="user-dropdown"
                  data-testid="user-menu"
                >
                  {admin && (
                    <NavDropdown.Item as={Link} to="/admin">
                      Admin
                    </NavDropdown.Item>
                  )}
                  <NavDropdown.Item as={Link} to="/my-profile">
                    My profile
                  </NavDropdown.Item>
                  <NavDropdown.Item
                    as={Link}
                    to="/"
                    data-testid="logout"
                    onClick={() => logoutUser()}
                  >
                    Logout
                  </NavDropdown.Item>
                </NavDropdown>
              ) : (
                <Nav.Link
                  as={Link}
                  to="/login"
                  activeClassName="active"
                  data-testid="login"
                >
                  Login
                </Nav.Link>
              )}
            </Nav>
          )}
        </Navbar.Collapse>
      </Container>
    </Navbar>
  </header >
)

export default connect(
  state => ({
    loaded: state.user.loaded,
    connected: state.user.connected,
    acceptedConditions: state.user.acceptedConditions,
    admin: state.user.admin,
    email: state.user.email,
  }),
  dispatch => ({
    logoutUser: () => dispatch(logOutUser()),
  }),
)(Header)
